import * as React from 'react'
import { Card, CardContent } from '@mui/material'
import { Title } from 'react-admin'
import { useDataProvider } from 'react-admin'

import Spinner from '../Spinner'

import { prettyNumber } from '../utils'
import config from '../config'

import './styles.scss'

export default function Invoicing() {
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [previewing, setPreviewing] = React.useState([])
  const [invoicing, setInvoicing] = React.useState([])
  const [monthOffset, setMonthOffset] = React.useState(0)
  const dataProvider = useDataProvider()

  const fetchData = async (offset) => {
    setLoading(true)
    const invoiceData = await dataProvider.getInvoiceData(offset)
    setData(invoiceData.data.data)
    setLoading(false)
  }

  const doPreview = async (transactionId) => {
    setPreviewing([...previewing, transactionId])
    const previewData = await dataProvider.doGenerateInvoicePDF(transactionId)
    const record = data.find(t => t.id === transactionId)
    const newData = data.filter(t => t.id !== transactionId).concat([{ ...record, pdf_filename: previewData.data.filename }])
    setData(newData)
    setPreviewing(previewing.filter(id => id !== transactionId))
    // setData(invoiceData.data.data)
    // setLoading(false)
  }

  const doInvoicing = async (transactionId) => {
    // setLoading(true)
    setInvoicing([...invoicing, transactionId])
    const invoiceData = await dataProvider.doTransactionInvoicing(transactionId)
    const record = data.find(t => t.id === transactionId)
    const newData = data.filter(t => t.id !== transactionId).concat([{ ...record, invoiced: true }])
    setData(newData)
    setInvoicing(invoicing.filter(id => id !== transactionId))
    // setData(invoiceData.data.data)
    // setLoading(false)
  }

  React.useEffect(() => {
    fetchData(monthOffset)
  }, [])

  return (
    <Card style={{ marginTop: '2rem' }} className="income-report-container invoicing-container">
      <Title title="Fakturering" />
      <CardContent>
        {(!data || loading) &&
          <div>Laster...</div>
        }
        {(data && !loading) &&
          <div>
            {data.length === 0 && <div>Ingen fakturaer å vise</div>}
            {data.length > 0 &&
              <div>
                <table className="income-report">
                  <thead>
                    <tr>
                      <th>Kunde</th>
                      <th className="num">Fakturerbart beløp</th>
                      <th colSpan="2"></th>
                    </tr>
                  </thead>
                  {data.sort((a, b) => a.amount > b.amount ? -1 : 1).map(transaction => {
                    return (
                      <tbody className="summary-row" key={transaction.id}>
                        <tr className="billing-account-row">
                          <td className="name">{transaction.billing_account.name}</td>
                          <td className="num amount">{prettyNumber(transaction.amount)}</td>
                          <td className="action">{previewing.includes(transaction.id) ? <Spinner /> : (transaction.pdf_filename ? <a href={`${config.apiUri}/invoice_pdfs/${transaction.pdf_filename}`} target="_blank" rel="noreferrer">Last ned PDF</a> : <button onClick={() => doPreview(transaction.id)} disabled={invoicing.includes(transaction.id)}>Generer PDF</button>)}</td>
                          <td className="action">{invoicing.includes(transaction.id) ? <Spinner /> : (transaction.invoiced ? 'Ferdig!' : <button onClick={() => doInvoicing(transaction.id)}>Fakturering</button>)}</td>
                        </tr>
                      </tbody>
                    )
                  })}
                  <tbody className="global-summary-row">
                    <tr>
                      <td>SUM</td>
                      <td className="num">{prettyNumber(data.reduce((acc, transaction) => acc + transaction.amount, 0))}</td>
                      <td colSpan="2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
          </div>
        }
      </CardContent >
    </Card >
  )
}
