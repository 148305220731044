import * as React from 'react'
import { Card, CardContent } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CollapseIcon from '@mui/icons-material/ExpandMore'
import { Title } from 'react-admin'
import { useDataProvider } from 'react-admin'
import { prettyNumber, groupBy, monthName } from '../utils'
import moment from 'moment'

import './styles.scss'

const EXCLUDED_BILLING_ACCOUNTS = [
  // B25 Holding
  'f93a99b1-ad7f-4d11-905a-44c74ebd0053',
  // Demo konto
  'de8ece04-42f0-4ad3-90ff-dae943c97407',
  // Levelup AS
  'd8f0bd7e-31f5-4f94-9d26-7ba29c1f7d6e',
  // OTI Greentech
  '421a8ae9-b521-4464-8c9f-84763667fef9',
  // Kravia
  'd6721270-56f3-4783-abaa-a442c80effb3',
  // Grefsen Utvikling
  'c9879ecd-1f3b-4a97-b46c-1e84e993c14c'
]

const BillingReport = () => {
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [expanded, setExpanded] = React.useState([])
  const [expandedServices, setExpandedServices] = React.useState([])
  const [monthOffset, setMonthOffset] = React.useState(0)
  const dataProvider = useDataProvider()

  const fetchData = async (offset) => {
    setLoading(true)
    const billingReportData = await dataProvider.getBillingReportData(offset)
    setData(billingReportData.data.data)
    setLoading(false)
  }

  React.useEffect(() => {
    fetchData(monthOffset)
  }, [])

  const toggleExpanded = (id) => {
    setExpanded(expanded.includes(id) ? expanded.filter(x => x !== id) : [...expanded, id])
  }

  const toggleExpandedService = (id) => {
    setExpandedServices(expandedServices.includes(id) ? expandedServices.filter(x => x !== id) : [...expandedServices, id])
  }

  const updateMonthOffset = async (offset) => {
    setMonthOffset(offset)
    await fetchData(offset)
  }

  const actualMonth = moment().add(monthOffset, 'months')
  const firstDay = actualMonth.startOf('month').format('YYYY-MM-DD')
  const daysSoFar = monthOffset === 0 ? Math.max(moment().date() - 1, 1) : actualMonth.daysInMonth()
  const daysInMonth = actualMonth.endOf('month').date()

  const withBillingReports = data
    ? data.billing_accounts
      .filter(ba => !EXCLUDED_BILLING_ACCOUNTS.includes(ba.id))
      .map(billingAccount => {
        const prevBillingSummaries = data.prev_billing_report_summary.filter(s => s.billing_account_id === billingAccount.id)
        const currBillingSummaries = data.curr_billing_report_summary.filter(s => s.billing_account_id === billingAccount.id)
        const totalIncomePrevMonth = prevBillingSummaries.reduce((acc, r) => acc + r.sub_total, 0)
        const totalIncomeThisMonth = currBillingSummaries.reduce((acc, r) => acc + r.sub_total, 0)
        const incomePerDay = totalIncomeThisMonth / daysSoFar
        const projectedIncome = incomePerDay * daysInMonth
        const absDiff = projectedIncome - totalIncomePrevMonth
        const percentDiff = absDiff !== 0 ? ((absDiff / totalIncomePrevMonth) * 100) : 0
        return [
          billingAccount,
          prevBillingSummaries,
          currBillingSummaries,
          totalIncomePrevMonth,
          totalIncomeThisMonth,
          incomePerDay,
          projectedIncome,
          absDiff,
          percentDiff,
        ]
      })
      .sort((a, b) => a[4] > b[4] ? -1 : 1)
    : []

  const sumPrevMonth = withBillingReports.reduce((acc, r) => acc + r[3], 0)
  const sumThisMonth = withBillingReports.reduce((acc, r) => acc + r[4], 0)
  const sumProjected = withBillingReports.reduce((acc, r) => acc + r[6], 0)
  const sumAbsDiff = withBillingReports.reduce((acc, r) => acc + r[7], 0)
  const sumPercentDiff = sumAbsDiff !== 0 ? ((sumAbsDiff / sumPrevMonth) * 100) : 0

  return (
    <Card style={{ marginTop: '2rem' }} className="income-report-container">
      <Title title="Inntektsrapport" />
      <CardContent>
        {(!data || loading) &&
          <div>Laster...</div>
        }
        {(data && !loading) &&
          <div>
            <div className="controls">
              <div className="prev-month"><div onClick={() => updateMonthOffset(monthOffset - 1)}><ChevronLeftIcon /></div></div>
              <div className="current-month">{monthName(monthOffset)}</div>
              <div className="next-month"><div onClick={() => updateMonthOffset(monthOffset + 1)} disabled={monthOffset === 0}><ChevronRightIcon /></div></div>
            </div>
            <table className="income-report">
              <thead>
                <tr>
                  <th></th>
                  <th>Kunde/produkt</th>
                  {Boolean(expanded.length) &&
                    <th>Instans</th>
                  }
                  <th className="num">Inntekt {monthOffset === 0 ? 'så langt denne mnd.' : monthName(monthOffset)}</th>
                  {monthOffset === 0 &&
                    <th className="num">Estimert {monthOffset === 0 ? 'hele denne mnd.' : 'aktuell måned'}</th>
                  }
                  <th className="num">Inntekt {monthOffset === 0 ? 'forrige mnd.' : 'måneden før'}</th>
                  <th className="num">Diff</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="global-summary-row">
                <tr>
                  <td></td>
                  <td>SUM</td>
                  {Boolean(expanded.length) &&
                    <td></td>
                  }
                  <td className="num">
                    <strong>{prettyNumber(sumThisMonth)}</strong>
                  </td>
                  {monthOffset === 0 &&
                    <td className="num">
                      <strong>{prettyNumber(sumProjected)}</strong>
                    </td>
                  }
                  <td className="num">
                    <strong>{prettyNumber(sumPrevMonth)}</strong>
                  </td>
                  <td className="num">
                    <strong>{prettyNumber(sumAbsDiff)}</strong>
                  </td>
                  <td className={`percentage ${sumPercentDiff > 5 ? 'good' : (sumPercentDiff < -5 ? 'bad' : '')}`}>
                    <strong>({prettyNumber(sumPercentDiff)}%)</strong>
                  </td>
                </tr>
              </tbody>
              {withBillingReports.map(combo => {
                const [
                  billingAccount,
                  billingSummariesPrevMonth,
                  billingSummariesThisMonth,
                  totalIncomePrevMonth,
                  totalIncomeThisMonth,
                  incomePerDay,
                  projectedIncome,
                  absDiff,
                  percentDiff,
                ] = combo
                const servicesWithReports = billingSummariesThisMonth
                  .map(summary => {
                    const service = data.services.find(s => s.id === summary.service_id)
                    const product = data.products.find(p => p.id === summary.product_id)
                    const income = summary.sub_total
                    const prevIncome = (data.prev_billing_report_summary.find(s => s.service_id === summary.service_id) || {}).sub_total || 0
                    return [service, product, income, prevIncome]
                  })
                  .sort((a, b) => a[2] > b[2] ? -1 : 1)
                const workspaceIds = [...new Set(servicesWithReports.map(s => s[0].workspace_id))]
                const workspaces = workspaceIds.map(id => data.workspaces.find(w => w.id === id))

                return (
                  <React.Fragment key={billingAccount.id}>
                    <tbody className="summary-row">
                      <tr className="billing-account-row" onClick={() => toggleExpanded(billingAccount.id)}>
                        <td className="toggle-expand">
                          {!expanded.includes(billingAccount.id) &&
                            <ChevronRightIcon />
                          }
                          {expanded.includes(billingAccount.id) &&
                            <CollapseIcon />
                          }
                        </td>
                        <td>
                          <div><strong>{billingAccount.name}</strong></div>
                        </td>
                        {Boolean(expanded.length) &&
                          <td></td>
                        }
                        <td className="num">
                          <strong>{prettyNumber(totalIncomeThisMonth)}</strong>
                        </td>
                        {monthOffset === 0 &&
                          <td className="num">
                            <strong>{prettyNumber(projectedIncome)}</strong>
                          </td>
                        }
                        <td className="num">
                          <strong>{prettyNumber(totalIncomePrevMonth)}</strong>
                        </td>
                        <td className="num">
                          <strong>{prettyNumber(absDiff)}</strong>
                        </td>
                        <td className={`percentage ${percentDiff > 5 ? 'good' : (percentDiff < -5 ? 'bad' : '')}`}>
                          <strong>({prettyNumber(percentDiff)}%)</strong>
                        </td>
                      </tr>
                    </tbody>
                    {expanded.includes(billingAccount.id) &&
                      <tbody className="detail-rows">
                        <tr>
                          <td></td>
                          <td colSpan="7" className="billing-account-id"><strong>billing_account_id: </strong>{billingAccount.id}</td>
                        </tr>
                        {workspaces.map(workspace => {
                          const servicesForWorkspace = workspace ? servicesWithReports.filter(s => s[0].workspace_id === workspace.id) : servicesWithReports.filter(s => !s[0].workspace_id)
                          const servicesSum = servicesForWorkspace.reduce((acc, s) => acc + s[2], 0)
                          const servicesIncomePerDay = servicesSum / daysSoFar
                          const servicesProjectedIncome = servicesIncomePerDay * daysInMonth
                          const servicesPrevSum = servicesForWorkspace.reduce((acc, s) => acc + (s[3] || 0), 0)
                          const servicesDiff = monthOffset === 0
                            ? servicesProjectedIncome - servicesPrevSum
                            : servicesSum - servicesPrevSum
                          const servicesDiffCompareWith = monthOffset === 0 ? servicesProjectedIncome : servicesSum
                          const servicesPercentDiff = servicesDiff !== 0 ? ((servicesDiff / servicesDiffCompareWith) * 100) : 0
                          const serviceContent = servicesForWorkspace.map(combo => {
                            const [service, product, income, prevIncome] = combo
                            // const income = reports.reduce((acc, r) => acc + r.sub_total, 0)
                            const svcIncomePerDay = income / daysSoFar
                            const svcProjectedIncome = svcIncomePerDay * daysInMonth
                            const svcAbsDiff = monthOffset === 0
                              ? svcProjectedIncome - prevIncome
                              : income - prevIncome
                            const svcDiffCompareWith = monthOffset === 0 ? svcProjectedIncome : income
                            const svcPercentDiff = svcAbsDiff !== 0 ? ((svcAbsDiff / svcDiffCompareWith) * 100) : 0
                            return (
                              <>
                                <tr key={service.id} onClick={() => toggleExpandedService(service.id)} className="detail-row">
                                  <td className="toggle-expand subtle">
                                    {!expandedServices.includes(service.id) &&
                                      <ChevronRightIcon />
                                    }
                                    {expandedServices.includes(service.id) &&
                                      <CollapseIcon />
                                    }
                                  </td>
                                  <td>{product.name}{expandedServices.includes(service.id) && <span className="product-id"><strong>product_id: </strong>{product.id}</span>}</td>
                                  <td>{service.name}</td>
                                  <td className="num">{prettyNumber(income)}</td>
                                  {monthOffset === 0 &&
                                    <td className="num">{prettyNumber(svcProjectedIncome)}</td>
                                  }
                                  <td className="num">{isNaN(prevIncome) ? '-' : prettyNumber(prevIncome)}</td>
                                  <td className="num">{isNaN(prevIncome) ? '-' : prettyNumber(svcAbsDiff)}</td>
                                  <td className={`percentage ${svcPercentDiff > 5 ? 'good' : (svcPercentDiff < -5 ? 'bad' : '')}`}>{(isNaN(prevIncome) || income === 0) ? '-' : prettyNumber(svcPercentDiff) + '%'}</td>
                                </tr>
                                {expandedServices.includes(service.id) &&
                                  <tr key={`${service.id}-svc`}>
                                    <td></td>
                                    <td colSpan="7" className="service-id"><strong>service_id: </strong>{service.id}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                }
                              </>
                            )
                          })
                          return (
                            <>
                              {workspace && workspaces.length > 1 &&
                                <tr className="workspace-name-row">
                                  <td></td>
                                  <td colSpan="2" className="workspace-name">{workspace.name}</td>
                                  <td className="num">{prettyNumber(servicesSum)}</td>
                                  {monthOffset === 0 &&
                                    <td className="num">{prettyNumber(servicesProjectedIncome)}</td>
                                  }
                                  <td className="num">{prettyNumber(servicesPrevSum)}</td>
                                  <td className="num">{prettyNumber(servicesDiff)}</td>
                                  <td className={`percentage ${servicesPercentDiff > 5 ? 'good' : (servicesPercentDiff < -5 ? 'bad' : '')}`}>{(isNaN(servicesPrevSum) || servicesSum === 0) ? '-' : prettyNumber(servicesPercentDiff) + '%'}</td>
                                </tr>
                              }
                              {serviceContent}
                            </>
                          )
                        })
                        }
                      </tbody>
                    }
                  </React.Fragment>
                )
              })}
            </table>
          </div>
        }
      </CardContent >
    </Card >
  )
}

export default BillingReport
