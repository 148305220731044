import config from './config'

const { apiUri } = config

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${apiUri}/sessions`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          console.log('Oh, here')
          throw new Error(response.status)
        }
        return response.json()
      })
      .then(auth => {
        localStorage.setItem('token', auth?.data?.token)
      })
      .catch(e => {
        if (e.message === '401') {
          throw new Error('Wrong username or password')
        } else {
          console.log('Yeah error', e.message)
          throw new Error('Network error')
        }
      })
  },

  logout: () => {
    localStorage.removeItem('token')
    return Promise.resolve()
  },

  checkAuth: () => {
    const token = localStorage.getItem('token')

    if (token) {
      if (window._auth_was_checked) {
        console.log('checkAuth already checked!')
        return Promise.resolve()
      } else {
        console.log('checkAuth initial check...')
        window._auth_was_checked = true
        return Promise.resolve()
      }
    } else {
      console.log('checkAuth - no token!')
      return Promise.reject()
    }
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token')
      return Promise.reject()
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },

  getIdentity: () =>
    Promise.resolve({
      id: 'user',
      fullName: 'John Doe',
    }),

  getPermissions: () => Promise.resolve(''),
}

export default authProvider