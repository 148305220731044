import moment from 'moment'

export function prettyNumber(x) {
  return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function monthName(offset) {
  if (offset === 0) {
    return 'Denne måneden'
  } else {
    const names = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']
    const actualMonthDate = moment().add(offset, 'months')
    const currYear = moment().year()
    const year = actualMonthDate.year()
    const month = actualMonthDate.month()
    const date = actualMonthDate.date()
    const name = names[month]
    if (year !== currYear) {
      return `${name} ${year}`
    } else {
      return name
    }
  }
}

