import { fetchUtils } from 'react-admin'
import { stringify } from 'query-string'
import config from './config'

const apiUrl = config.apiUri
const httpClient = fetchUtils.fetchJson

function httpOptions(config, overrides = {}) {
  const token = localStorage.getItem('token')
  const options = {}
  if (token) {
    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    }
  }

  return {
    ...options,
    ...overrides,
  }
}

const DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    const { headers, json } = await httpClient(url, httpOptions(config))
    return {
      data: json.data,
      // total: parseInt(headers.get('content-range').split('/').pop(), 10),
      total: json.data.length,
    }
  },

  getOne: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, httpOptions(config))
    return {
      data: json,
    }
  },

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    const { json } = await httpClient(url, httpOptions(config))
    return {
      data: json,
    }
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    const { headers, json } = await httpClient(url, httpOptions(config))
    return {
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }
  },

  update: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, httpOptions(config, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }))
    return { data: json }
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, httpOptions(config, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }))
    return { data: json }
  },

  create: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}`, httpOptions(config, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }))
    return {
      data: { ...params.data, id: json.id },
    }
  },

  delete: async (resource, params) => {
    const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}`, httpOptions(config, {
      method: 'DELETE',
    }))
    return { data: json }
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, httpOptions(config, {
      method: 'DELETE',
    }))
    return { data: json }
  },

  getBillingReportData: async (monthOffset) => {
    const { json } = await httpClient(`${apiUrl}/income_report_data?month_offset=${monthOffset}`, httpOptions(config))
    return { data: json }
  },

  getInvoiceData: async (monthOffset) => {
    const { json } = await httpClient(`${apiUrl}/transactions?month_offset=${monthOffset}`, httpOptions(config))
    return { data: json }
  },

  doGenerateInvoicePDF: async (transactionId) => {
    const { json } = await httpClient(`${apiUrl}/transactions/${transactionId}/generate_invoice_pdf`, httpOptions(config, {
      method: 'POST',
    }))
    return { data: json }
  },

  doTransactionInvoicing: async (transactionId) => {
    const { json } = await httpClient(`${apiUrl}/transactions/${transactionId}/do_full_invoicing`, httpOptions(config, {
      method: 'POST',
    }))
    return { data: json }
  },

}

export default DataProvider
