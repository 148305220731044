import * as React from 'react'
import { Admin, Resource, ListGuesser, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'
// import jsonServerProvider from 'ra-data-json-server'
import { Layout } from './Layout'
import IncomeReport from './IncomeReport'
import Invoicing from './Invoicing'
// import LoginPage from './LoginPage'

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com')
import dataProvider from './dataProvider'
import authProvider from './authProvider'

const App = () => (
  <Admin layout={Layout} dataProvider={dataProvider} authProvider={authProvider}>
    <Resource name="services" list={ListGuesser} />
    <Resource name="products" list={ListGuesser} />
    <Resource name="users" list={ListGuesser} />
    <CustomRoutes>
      <Route path="/incomereport" element={<IncomeReport />} />
      <Route path="/invoicing" element={<Invoicing />} />
    </CustomRoutes>
  </Admin >
)

export default App
