import * as React from 'react'
import { DashboardMenuItem, Menu as RaMenu, MenuItemLink } from 'react-admin'
import BookIcon from '@mui/icons-material/Book'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import IncomeReportIcon from '@mui/icons-material/BarChart'
import InvoicingIcon from '@mui/icons-material/Receipt'
// import LabelIcon from '@mui/icons-material/Label'

const Menu = (props) => (
  <RaMenu {...props}>
    {/* <DashboardMenuItem /> */}
    <MenuItemLink to="/incomereport" primaryText="Inntektsrapport" leftIcon={<IncomeReportIcon />} />
    <MenuItemLink to="/invoicing" primaryText="Fakturering" leftIcon={<InvoicingIcon />} />
    <MenuItemLink to="/services" primaryText="Tjenester" leftIcon={<BookIcon />} />
    <MenuItemLink to="/products" primaryText="Produkter" leftIcon={<ChatBubbleIcon />} />
  </RaMenu>
)

export { Menu }